import React from "react"
import "../assets/css/index.css"
import Seo from "../components/seo/Seo"

import Section from "../components/section/Section"
import SectionGrid from "../components/section/SectionGrid"
import SectionImage from "../components/section/SectionImage"
import SectionInfo from "../components/section/SectionInfo"
import SectionHeading from "../components/section/SectionHeading"
import SectionParagraph from "../components/section/SectionParagraph"

import ueberblick from "../assets/images/presentational/ueberblick.webp"
import expertise from "../assets/images/presentational/expertise.webp"
import karriere from "../assets/images/presentational/karriere.webp"
import SectionLink from "../components/section/SectionLink"

const Home = () => {
  return (
    <>
      <Seo
        title={"Immobilien- und Projektmanagement | Avalum"}
        description={
          "Der Immobiliendienstleister, Spezialisten für ein umfassendes Management von Einzelobjekten und Immobilienportfolien mit Sitz im Frankfurt am Main"
        }
      />
      <Section>
        <SectionGrid>
          <SectionImage src={ueberblick} />
          <SectionInfo>
            <SectionHeading>Überblick</SectionHeading>
            <SectionParagraph>
              Wir sind ein Immobiliendienstleister für umfassendes
              Immobilienmanagement von Einzelobjekten und
              Immobilienangelegenheiten mit dem Firmensitz in Frankfurt am Main.
            </SectionParagraph>
            <SectionLink
              to={"/ueber-uns"}
              title={"Lernen Sie uns besser kennen"}
            />
          </SectionInfo>
        </SectionGrid>
      </Section>
      <Section isEven={true}>
        <SectionGrid sx={"md:flex-row-reverse"}>
          <SectionImage src={expertise} />
          <SectionInfo>
            <SectionHeading>Geschäftsfelder</SectionHeading>
            <SectionParagraph>
              Unsere Leistungen beruhen auf sachlichen Überlegungen, die
              höchsten Herausforderungen gerecht werden.
            </SectionParagraph>
            <SectionParagraph>
              Stets sind wir bestrebt, Beratungen und Leistungen anzubieten, die
              eine bedachte und gewinnbringende Bewirtschaftung garantieren.
            </SectionParagraph>
            <SectionLink
              to={"/geschaeftsfelder"}
              title={"Geschäftsfelder ansehen"}
            />
          </SectionInfo>
        </SectionGrid>
      </Section>
      <Section id={"karriere"}>
        <SectionGrid>
          <SectionImage src={karriere} />
          <SectionInfo>
            <SectionHeading>Karriere</SectionHeading>
            <SectionParagraph>
              Sie haben höchste Qualitätsansprüche und Freude an proaktiver und
              selbstständiger Arbeit?
            </SectionParagraph>
            <SectionParagraph>
              Dann haben wir das Richtige für Sie! Wir leben vom hohen
              Engagement, fachlichen Know-how und den persönlichen Fähigkeiten
              unserer Mitarbeiter.
            </SectionParagraph>
            <SectionParagraph>
              Erfahre mehr über unsere offenen Stellen und über uns.
            </SectionParagraph>
            <SectionParagraph>
              Wir freuen uns auf Ihre Bewerbung!
            </SectionParagraph>
            <SectionLink to={"/kontakt"} title={"Jetzt bewerben"} />
          </SectionInfo>
        </SectionGrid>
      </Section>
    </>
  )
}

export default Home
